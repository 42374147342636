import { DYNAMIC_FEATURE_X_Y_FIELD_NAME } from "./constants";

export function getSelectableMetadata(baseSelectableMetadata) {
    const resultArray = [{ label: "x_y", id: DYNAMIC_FEATURE_X_Y_FIELD_NAME }];
    baseSelectableMetadata.forEach((metadata) => {
        if (metadata === "plot_id") {
            resultArray.push({ label: "hiphen_id", id: "plot_id" });
        } else if (metadata === "client_id") {
            resultArray.push({ label: "plot_id", id: "client_id" });
        } else {
            resultArray.push({ label: metadata, id: metadata });
        }
    });
    return resultArray;
}
