import "leaflet/dist/leaflet.css";

import {
    Autocomplete,
    Box,
    Button,
    Container,
    Dialog,
    Drawer,
    Fab,
    Skeleton,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
    DYNAMIC_FEATURE_ID_FIELD_NAME,
    DYNAMIC_FEATURE_X_Y_FIELD_NAME,
} from "./utils/constants";
import {
    GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES,
    LEAFLET_OVERLAYED_BUTTONS_ZINDEX,
    MUI_DEFAULT_SIZES,
    SYSTEMS,
} from "../../constants";
import { GeoJSON, MapContainer, TileLayer, WMSTileLayer } from "react-leaflet";
import {
    PARCELLAIRE_REDUCER_ACTIONS,
    initialParcellaireState,
    parcellaireReducer,
} from "./parcellaireReducer";
import {
    ParcellaireCreationContainer,
    ParcellaireCreationFormNoImport,
    ParcellaireLiteralMetadataForm,
    VectorPolyline,
} from ".";
import { readGeoJson, readMetadata, removeToolId } from "./utils/fileIoUtils";
import { useCallback, useMemo, useReducer, useRef, useState } from "react";

import { APPBAR_HEIGHT } from "../../layouts/orders/constants";
import AccountPopover from "../../layouts/orders/AccountPopover";
import AddIcon from "@mui/icons-material/Add";
import { BACKEND_ROUTES } from "../../backendRoutes";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Geoman } from "./Geoman";
import L from "leaflet";
import LastPageIcon from "@mui/icons-material/LastPage";
import LockIcon from "@mui/icons-material/Lock";
import Logo from "../../components/Logo";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { applyTransform } from "./utils/applyTransform";
import { geoJSONUtil } from "./utils/geoJSONUtil";
import { getDefaultValuesParcellaireForm } from "./utils/getDefaultValuesParcellaireForm";
import { getMapTiles } from "./utils/getMapTiles";
import { useParams } from "react-router-dom";
import useSWRImmutable from "swr/immutable";
import { useSnackbar } from "../../hooks";

export function Parcellaire({ isLiteral }) {
    const { openSnackbar } = useSnackbar();

    const { siteUuid } = useParams();

    const mapRef = useRef(null);

    const [parcellaireState, parcellaireDispatch] = useReducer(
        parcellaireReducer,
        initialParcellaireState
    );

    const [geoJsonKey, setGeoJsonKey] = useState(new Date().getTime());

    const [toggles, setToggles] = useState([
        GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.LOCKED_SHAPE_MODE,
    ]);

    const [hiddenPlotMode, setHiddenPlotMode] = useState(false);

    const [displayBlue, setDisplayBlue] = useState(false);

    const [blueRectangleData, setBlueRectangleData] = useState(null);

    const [orthoimageName, setOrthoimageName] = useState("plain");

    const [plotColor, setPlotColor] = useState("#3388ff");

    const [openDrawer, setOpenDrawer] = useState(isLiteral ? true : false);

    const [openExportForm, setOpenExportForm] = useState(false);

    const [coordMarkerMode, setCoordMarkerMode] = useState(false);

    const [selectableMetadata, setSelectableMetadata] = useState([]);

    const clearAllGeoJsons = () => {
        parcellaireDispatch({
            type: PARCELLAIRE_REDUCER_ACTIONS.CLEAR_ALL_GEOJSON,
        });
    };

    const methods = useForm({
        defaultValues: getDefaultValuesParcellaireForm(isLiteral),
    });

    const selectedMetadataWatch = methods.watch("selectedMetadata");

    const { data: site, error: siteFetchError } = useSWRImmutable(
        isLiteral ? `${BACKEND_ROUTES.SITE}/${siteUuid}?parentInfo=true` : null
    );
    const { data: pipelineTemplates, error: pipelineTemplatesFetchError } =
        useSWRImmutable(
            site
                ? `${BACKEND_ROUTES.PIPELINE_TEMPLATE}?parentInfo=true&contractUuid=${site.contractUuid}&sort=name`
                : null
        );

    const filteredPipelineTemplates = useMemo(
        () =>
            pipelineTemplates && site
                ? pipelineTemplates.rows.filter(
                      (PT) =>
                          PT.AcquisitionVector.SystemModel.system ===
                          SYSTEMS.LITERAL
                  )
                : [],
        [pipelineTemplates, site]
    );

    const handleToggleGroupChange = useCallback(
        (event, values) => {
            try {
                const transform = applyTransform(parcellaireState, values);
                setToggles(values);
                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.PM_EDIT,
                    transform,
                });
            } catch (error) {
                // TODO: error boundary https://hiphen.atlassian.net/browse/HCC-822
                openSnackbar(
                    error instanceof RangeError
                        ? rangeErrorString
                        : unknownErrorString,
                    "error"
                );
            }
            setGeoJsonKey(new Date().getTime());
        },
        [openSnackbar, parcellaireState]
    );

    const mergedFetchError = siteFetchError ?? pipelineTemplatesFetchError;

    if (isLiteral && mergedFetchError)
        return <FetchErrorAlert error={mergedFetchError} />;

    if (isLiteral && (!site || !pipelineTemplates)) {
        return (
            <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="100vh"
            />
        );
    }

    const geoJsonPointToLayer = (feature, latlng) => {
        // this transforms markers for points into circle features (that cannot be interacted with, for now)
        return feature.id
            ? L.circleMarker(latlng, {
                  interactive: false,
                  radius: 5,
              }).bindTooltip(feature.id, {
                  permanent: true,
                  direction: "top",
              })
            : L.circleMarker(latlng, {
                  interactive: false,
                  radius: 5,
              });
    };

    const geoJsonOnEachFeature = (feature, layer) => {
        layer.setStyle({ color: plotColor });
        if (parcellaireState.selectedLayers.length) {
            const style = parcellaireState.selectedLayers.find(
                (selectedLayer) =>
                    geoJSONUtil.getIdOfFeature(selectedLayer.feature) ===
                    geoJSONUtil.getIdOfFeature(feature)
            )
                ? {
                      color: plotColor,
                      opacity: 1.0,
                      interactive: true,
                  }
                : {
                      color: "black",
                      opacity: 0.4,
                      interactive: false,
                  };

            layer.setStyle(style);
        } else {
            if (
                parcellaireState.lockedLayers.find(
                    (lockedLayer) =>
                        geoJSONUtil.getIdOfFeature(lockedLayer.feature) ===
                        geoJSONUtil.getIdOfFeature(feature)
                )
            )
                layer.setStyle({
                    color: "black",
                    opacity: 1.0,
                    interactive: false,
                });
        }

        // sets layer color to white if it has been moved
        if (
            parcellaireState.transformModeVectors.find(
                (vector) =>
                    vector.shapeObjectId === geoJSONUtil.getIdOfFeature(feature)
            )
        ) {
            layer.setStyle({ color: "white" });
        }

        layer.bindPopup(
            `Name: ${feature.properties.name ?? feature.properties.plot_id ?? `X${feature.properties.x}_Y${feature.properties.y}`}<br/>Row: ${feature.properties.y}<br/>Column: ${feature.properties.x}`
        );

        mapRef.current.pm
            .getGeomanLayers()
            .filter(
                (layer) =>
                    layer.options.html ===
                    feature.properties[DYNAMIC_FEATURE_ID_FIELD_NAME]
            )
            .forEach((layer) => {
                layer.remove();
            });

        selectedMetadataWatch &&
            !hiddenPlotMode &&
            L.marker(layer.getBounds().getCenter(), {
                icon: L.divIcon({
                    className: "",
                    html: `<b style="display:inline-block; transform:translate(-50%, -50%); color: white;text-shadow:-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000;"> ${selectedMetadataWatch.id === DYNAMIC_FEATURE_X_Y_FIELD_NAME ? `${feature.properties.x}_${feature.properties.y}` : feature.properties[selectedMetadataWatch.id]} </b>`,
                    iconSize: "auto",
                }),
                html: feature.properties[DYNAMIC_FEATURE_ID_FIELD_NAME],
                customType: `idLabel`,
                interactive: false,
            }).addTo(mapRef.current);

        // triggers when the layer is moved
        layer.on("pm:edit", (e) => {
            const shapeObjectId = geoJSONUtil.getIdOfFeature(feature);

            const oldFeature = geoJSONUtil.findFeatureById(
                parcellaireState.checkpointGeoJsonData,
                shapeObjectId
            );
            const oldCenter = geoJSONUtil.getCenterOfFeature(oldFeature);

            const layerCenter = layer.getCenter();
            const newCenter = [layerCenter.lng, layerCenter.lat];

            const vector = {
                shapeObjectId,
                longitude: newCenter[0] - oldCenter[0],
                latitude: newCenter[1] - oldCenter[1],
            };

            // shapeObjectId, vector, toggles, setTransformError are required
            const vectorIndex = parcellaireState.transformModeVectors.findIndex(
                (vector) => vector.shapeObjectId === shapeObjectId
            );

            const newTransformModeVectors =
                vectorIndex === -1
                    ? parcellaireState.transformModeVectors.concat(vector)
                    : parcellaireState.transformModeVectors.toSpliced(
                          vectorIndex,
                          1,
                          vector
                      );

            try {
                const transform = applyTransform(
                    parcellaireState,
                    toggles,
                    newTransformModeVectors
                );

                parcellaireDispatch({
                    type: PARCELLAIRE_REDUCER_ACTIONS.PM_EDIT,
                    transform,
                });
            } catch (error) {
                // TODO: error boundary https://hiphen.atlassian.net/browse/HCC-822
                openSnackbar(
                    error instanceof RangeError
                        ? rangeErrorString
                        : unknownErrorString,
                    "error"
                );
            }

            setGeoJsonKey(new Date().getTime());
        });
    };

    const blueRectangleOnFeature = (_, layer) => {
        layer.setStyle({
            color: "black",
            opacity: 0.1,
            interactive: false,
        });
        layer.bringToBack();
    };

    return (
        <Stack direction="column">
            <Box
                sx={{ minHeight: APPBAR_HEIGHT, px: 3 }}
                display="flex"
                alignItems="center"
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ width: 1 }}
                >
                    <Logo
                        sx={{
                            height: MUI_DEFAULT_SIZES.BUTTON.MEDIUM,
                            width: (MUI_DEFAULT_SIZES.BUTTON.MEDIUM * 5) / 3,
                        }}
                    />

                    <Button
                        variant="contained"
                        component="label"
                        startIcon={<FileUploadIcon />}
                        sx={{ textTransform: "none" }}
                    >
                        Import plot map
                        <input
                            hidden
                            type="file"
                            accept=".geojson"
                            onChange={(event) => {
                                const geoJsonFile = event.target.files[0];

                                if (geoJsonFile) {
                                    if (parcellaireState.initialGeoJsonData)
                                        clearAllGeoJsons();

                                    readGeoJson(
                                        geoJsonFile,
                                        parcellaireDispatch,
                                        mapRef,
                                        null,
                                        openSnackbar,
                                        false,
                                        setSelectableMetadata,
                                        isLiteral
                                    );

                                    // removing coord marker
                                    mapRef.current.pm
                                        .getGeomanLayers()
                                        .filter(
                                            (layer) => layer instanceof L.Marker
                                        )
                                        .forEach((layer) => {
                                            layer.remove();
                                        });

                                    setBlueRectangleData(null);

                                    methods.setValue("selectedMetadata", null);
                                }
                            }}
                        />
                    </Button>
                    {!isLiteral && (
                        <Button
                            variant="contained"
                            component="label"
                            startIcon={<FileUploadIcon />}
                            sx={{ textTransform: "none" }}
                        >
                            Json session-metadata
                            <input
                                hidden
                                type="file"
                                accept=".json"
                                onChange={(event) => {
                                    readMetadata(
                                        event,
                                        parcellaireDispatch,
                                        setGeoJsonKey
                                    );
                                }}
                            />
                        </Button>
                    )}
                    {!isLiteral && (
                        <TextField
                            label="Name of the orthoimage"
                            value={orthoimageName}
                            onChange={(event) => {
                                setOrthoimageName(event.target.value);
                            }}
                        />
                    )}
                    <TextField
                        label="Plot color"
                        id="microplotColorPicker"
                        type="color"
                        sx={{ width: "120px" }}
                        value={plotColor}
                        onChange={(e) => {
                            setPlotColor(e.target.value);
                            setGeoJsonKey(new Date().getTime());
                        }}
                    />
                    {isLiteral ? (
                        <Button
                            variant="contained"
                            component="label"
                            startIcon={<FileDownloadIcon />}
                            color="info"
                            sx={{ textTransform: "none" }}
                            onClick={() => {
                                setOpenExportForm(true);
                            }}
                            disabled={!parcellaireState.transformedGeoJson}
                        >
                            Export plot map
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            component="a"
                            startIcon={<FileDownloadIcon />}
                            color="info"
                            sx={{ textTransform: "none" }}
                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                JSON.stringify(
                                    removeToolId(
                                        parcellaireState.transformedGeoJson
                                    )
                                )
                            )}`}
                            download="plotmap.geojson"
                            disabled={!parcellaireState.transformedGeoJson}
                        >
                            Export plot map
                        </Button>
                    )}
                    {isLiteral && (
                        <Button
                            variant="contained"
                            component="label"
                            disabled={!parcellaireState.initialGeoJsonData}
                            startIcon={<FileDownloadIcon />}
                            color="info"
                            sx={{ textTransform: "none" }}
                            onClick={() => {
                                getMapTiles(mapRef.current, parcellaireState);
                            }}
                        >
                            Export map tiles
                        </Button>
                    )}
                    {Boolean(selectableMetadata?.length) && (
                        <Controller
                            control={methods.control}
                            name="selectedMetadata"
                            render={({
                                field: { ref, onChange, name, ...fieldProps },
                            }) => (
                                <Autocomplete
                                    {...fieldProps}
                                    id="selectedMetadata"
                                    options={selectableMetadata}
                                    sx={{ width: "200px" }}
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => {
                                        onChange(value);
                                        setGeoJsonKey(new Date().getTime());
                                    }}
                                    componentsProps={{
                                        popper: {
                                            style: { width: "fit-content" },
                                        },
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputRef={ref}
                                            name={name}
                                            fullWidth
                                            label="Displayed metadata"
                                        />
                                    )}
                                />
                            )}
                        />
                    )}
                    <Box
                        sx={{
                            bgcolor: "white",
                            position: "absolute",
                            bottom: 140,
                            left: 0,
                            zIndex: LEAFLET_OVERLAYED_BUTTONS_ZINDEX,
                        }}
                        style={{ borderRadius: "8px" }}
                    >
                        <Stack>
                            {!isLiteral && (
                                <ToggleButton
                                    color="primary"
                                    value={
                                        blueRectangleData ? displayBlue : false
                                    }
                                    size="small"
                                    selected={
                                        blueRectangleData ? displayBlue : false
                                    }
                                    onChange={() => {
                                        setDisplayBlue(!displayBlue);
                                        setGeoJsonKey(new Date().getTime());
                                    }}
                                    disabled={!blueRectangleData}
                                >
                                    <Tooltip
                                        placement="right"
                                        title={
                                            <Typography>
                                                Display field map
                                            </Typography>
                                        }
                                    >
                                        <CropSquareIcon />
                                    </Tooltip>
                                </ToggleButton>
                            )}
                            <ToggleButton
                                color="primary"
                                value={hiddenPlotMode}
                                size="small"
                                selected={hiddenPlotMode}
                                onChange={() => {
                                    if (!hiddenPlotMode)
                                        mapRef.current.pm
                                            .getGeomanLayers()
                                            .filter(
                                                (layer) =>
                                                    layer.options.customType ===
                                                    "idLabel"
                                            )
                                            .forEach((layer) => {
                                                layer.remove();
                                            });
                                    setHiddenPlotMode(!hiddenPlotMode);
                                    setGeoJsonKey(new Date().getTime());
                                }}
                            >
                                <Tooltip
                                    placement="right"
                                    title={
                                        <Typography>Hide all plots</Typography>
                                    }
                                >
                                    <VisibilityOffIcon />
                                </Tooltip>
                            </ToggleButton>
                        </Stack>
                    </Box>
                    <ToggleButtonGroup
                        color="secondary"
                        value={toggles}
                        orientation="vertical"
                        onChange={handleToggleGroupChange}
                        sx={{
                            bgcolor: "white",
                            position: "absolute",
                            bottom: 10,
                            left: 0,
                            zIndex: LEAFLET_OVERLAYED_BUTTONS_ZINDEX,
                        }}
                    >
                        <ToggleButton
                            value={
                                GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.SINGLE_TARGET_MODE
                            }
                            size="small"
                            color="primary"
                        >
                            <Tooltip
                                placement="right"
                                title={
                                    <>
                                        <Typography>
                                            Apply only single translations
                                        </Typography>
                                        <Typography>
                                            (Overrides other modes)
                                        </Typography>
                                    </>
                                }
                            >
                                <LooksOneIcon />
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton
                            value={GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.SHIFT_MODE}
                            size="small"
                            color="primary"
                        >
                            <Tooltip
                                placement="right"
                                title={
                                    <Typography>
                                        Apply translation starting from the 1st
                                        vector
                                    </Typography>
                                }
                            >
                                <ControlCameraIcon />
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton
                            value={
                                GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES.LOCKED_SHAPE_MODE
                            }
                            size="small"
                            color="primary"
                        >
                            <Tooltip
                                placement="right"
                                title={
                                    <Typography>
                                        Do not change plot shape
                                    </Typography>
                                }
                            >
                                <LockIcon />
                            </Tooltip>
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Box sx={{ flexGrow: 1 }} />
                    <AccountPopover />
                </Stack>
            </Box>

            <div
                id="map"
                style={{
                    width: "100%",
                    height: `calc(100vh - ${APPBAR_HEIGHT}px)`,
                }}
            >
                <MapContainer
                    attributionControl={false}
                    center={[26.0, 30.0]}
                    maxZoom={25}
                    minZoom={2}
                    zoom={2}
                    style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: "url(/static/mapbg.png)",
                        backgroundSize: "1300px",
                    }}
                    ref={mapRef}
                >
                    <TileLayer
                        url={
                            isLiteral
                                ? "https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                : "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        }
                        minZoom={2}
                        maxNativeZoom={isLiteral ? 19 : 17}
                        maxZoom={25}
                    />
                    {parcellaireState.transformModeVectors?.map((vector) => (
                        <VectorPolyline
                            key={vector.shapeObjectId}
                            vector={vector}
                            checkpointGeoJsonData={
                                parcellaireState.checkpointGeoJsonData
                            }
                        />
                    ))}
                    {displayBlue && blueRectangleData && (
                        <GeoJSON
                            key={`${geoJsonKey}-blueRectangle`}
                            data={blueRectangleData}
                            bubblingMouseEvents={false}
                            onEachFeature={blueRectangleOnFeature}
                            pointToLayer={geoJsonPointToLayer}
                        />
                    )}
                    {!hiddenPlotMode && parcellaireState.transformedGeoJson && (
                        <GeoJSON
                            key={geoJsonKey}
                            data={parcellaireState.transformedGeoJson}
                            bubblingMouseEvents={false}
                            onEachFeature={geoJsonOnEachFeature}
                            pointToLayer={geoJsonPointToLayer}
                        />
                    )}
                    {parcellaireState.metadata && (
                        <WMSTileLayer
                            key={`cloverfield:${parcellaireState.metadata.site.id}-${parcellaireState.metadata.date}-${orthoimageName}`}
                            layers={`cloverfield:${parcellaireState.metadata.site.id}-${parcellaireState.metadata.date}-${orthoimageName}`}
                            url={process.env.REACT_APP_GEOSERVER_URL}
                            format="image/png"
                            transparent
                            opacity={1}
                            maxZoom={25}
                            tiled
                        />
                    )}
                    <Geoman
                        parcellaireState={parcellaireState}
                        parcellaireDispatch={parcellaireDispatch}
                        setGeoJsonKey={setGeoJsonKey}
                        setValue={methods.setValue}
                        reset={methods.reset}
                        coordMarkerMode={coordMarkerMode}
                        setCoordMarkerMode={setCoordMarkerMode}
                    />
                </MapContainer>
            </div>
            {isLiteral ? (
                <Drawer
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: drawerWidth,
                            bgcolor: "background.neutral",
                        },
                    }}
                    anchor="right"
                >
                    <Container>
                        <DrawerHeader>
                            <Typography variant="h5">
                                Plot map creation
                            </Typography>
                        </DrawerHeader>
                        <FormProvider {...methods}>
                            <ParcellaireCreationFormNoImport
                                parcellaireDispatch={parcellaireDispatch}
                                mapRef={mapRef}
                                setGeoJsonKey={setGeoJsonKey}
                                setBlueRectangleData={setBlueRectangleData}
                                isLiteral={true}
                                site={site}
                                coordMarkerMode={coordMarkerMode}
                                setCoordMarkerMode={setCoordMarkerMode}
                                openSnackbar={openSnackbar}
                                setSelectableMetadata={setSelectableMetadata}
                            />
                        </FormProvider>
                    </Container>
                </Drawer>
            ) : (
                <Drawer
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: drawerWidth,
                            bgcolor: "background.neutral",
                        },
                    }}
                    anchor="right"
                >
                    <DrawerHeader>
                        <Typography variant="h5">Plot map creation</Typography>
                    </DrawerHeader>
                    <FormProvider {...methods}>
                        <ParcellaireCreationContainer
                            setIsOpen={setOpenDrawer}
                            parcellaireDispatch={parcellaireDispatch}
                            mapRef={mapRef}
                            setGeoJsonKey={setGeoJsonKey}
                            openSnackbar={openSnackbar}
                            setBlueRectangleData={setBlueRectangleData}
                            coordMarkerMode={coordMarkerMode}
                            setCoordMarkerMode={setCoordMarkerMode}
                            setSelectableMetadata={setSelectableMetadata}
                        />
                    </FormProvider>
                </Drawer>
            )}
            {isLiteral && (
                <Dialog open={openExportForm} fullWidth maxWidth="xs">
                    <ParcellaireLiteralMetadataForm
                        setIsOpen={setOpenExportForm}
                        parcellaireState={parcellaireState}
                        siteUuid={siteUuid}
                        openSnackbar={openSnackbar}
                        site={site}
                        filteredPipelineTemplates={filteredPipelineTemplates}
                    />
                </Dialog>
            )}
            <Fab
                size={openDrawer ? "small" : "large"}
                color="primary"
                variant={openDrawer ? "circular" : "extended"}
                sx={(theme) =>
                    openDrawer
                        ? {
                              position: "fixed",
                              right: 32 + drawerWidth - 52,
                              top: 8,
                              zIndex: theme.zIndex.drawer,
                          }
                        : {
                              position: "fixed",
                              right: 26,
                              top: 76,
                              zIndex: theme.zIndex.drawer,
                          }
                }
                onClick={() => setOpenDrawer(!openDrawer)}
            >
                {!openDrawer && "Create New Plot Map"}
                {openDrawer ? <LastPageIcon /> : <AddIcon />}
            </Fab>
        </Stack>
    );
}

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "center",
}));

const drawerWidth = 400;

const rangeErrorString =
    "Move not allowed since transform calculation impossible.";

const unknownErrorString =
    "An unknown error has occured. Please try again later.";
